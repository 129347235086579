<template>
  <el-col class="page" >
    <el-col class="title">工单管理</el-col>
    <el-col class="pane-container">
      <el-col class="pane" @click.native="paneActive('1')">
        <el-button @click="newAWork();">新建工单</el-button>
      </el-col>
    </el-col>

    <el-col class="table-container">
      <el-table
          :data="tableData"
          v-loading="loading"
          border
          style="width: 100%">
        <el-table-column
            prop="number"
            label="工单编号">
        </el-table-column>
        <el-table-column
            prop="title"
            label="标题">
        </el-table-column>
        <el-table-column
            prop="state"
            :formatter ="stateFormatter"
            label="状态">
        </el-table-column>

        <el-table-column
            :formatter ="typeFormatter"
            prop="type"
            label="问题类型">
        </el-table-column>

        <el-table-column
            prop="contactPhone"
            label="联系电话">
        </el-table-column>

        <el-table-column
            prop="email"
            label="联系邮箱">
        </el-table-column>

        <el-table-column
            prop="createTime"
            label="	创建时间">
        </el-table-column>

        <el-table-column
            prop="name"
            label="操作"
            align="center">
          <template slot-scope="scope">
            <el-button  size="mini" @click="openEditDrawer(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>



    </el-col>
    <el-col style="margin-top: 20px">
      <Page :total="page.total"  :current="page.pageNo" :page-size="page.pageSize"
            @on-page-size-change="onPageSizeChange" @on-change="changePage" show-total show-elevator style="font-size: 14px"></Page>
    </el-col>


    <el-drawer
        title="新建工单"
        :visible.sync="drawer"
        direction="rtl"
        size="550px"
        class="drawer">
      <el-form :model="numberValidateForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm">

        <el-form-item
            label="问题类别"
            prop="type">
          <!--          <el-input type="postalCode" v-model.number="numberValidateForm.postalCode" autocomplete="off" placeholder="请输入收件地址邮政编码"></el-input>-->
          <el-radio-group v-model="numberValidateForm.type">
            <el-radio :label="1">基础产品</el-radio>
            <el-radio :label="2">云产品</el-radio>
            <el-radio :label="3">解决方案</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
            label="标题"
            prop="title"
            :rules="[
           { required: true, message: '标题不能为空'}
          ]"
        >
          <el-input type="phone" v-model.number="numberValidateForm.title" autocomplete="off" placeholder="请输入"></el-input>
        </el-form-item>


        <el-form-item
            label="问题描述"
            prop="content"
            :rules="[
           { required: true, message: '问题描述不能为空'}
          ]">
            <el-input type="textarea" v-model="numberValidateForm.content" maxlength="500" show-word-limit></el-input>
        </el-form-item>
        <el-form-item
            label="邮箱"
            prop="email"
            :rules="rules.email">
          <el-input type="email" v-model="numberValidateForm.email" placeholder="请输入收件人邮箱"></el-input>
        </el-form-item>

        <el-form-item
            label="手机号码"
            prop="contactPhone"
            :rules="rules.phone"
            >
          <el-input type="describeAddress" v-model.number="numberValidateForm.contactPhone"
                    autocomplete="off" placeholder="请输入收件人详细地址"></el-input>
        </el-form-item>

        <el-form-item
            label="上传图片"
            prop="file"
        >
          <el-upload
              class="upload-demo"
              action=""
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :http-request="uploadFileOrImg"
              :before-upload="beforUpload"
              multiple
              :limit="4"
              :on-exceed="handleExceed"
              :file-list="numberValidateForm.urlList">
            <el-button size="small">点击上传</el-button>
          </el-upload>

        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('numberValidateForm')">提交</el-button>
          <el-button @click="resetForm('numberValidateForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>

    <el-col></el-col>
    <el-drawer
        title="工单详情"
        :visible.sync="editDrawer"
        direction="rtl"
        size="550px"
        class="drawer">
      <el-form :model="editNumberValidateForm" ref="editNumberValidateForm" label-width="100px" class="demo-ruleForm">

        <el-row class="reply-content">
          <div v-for="(item,idx) in editData" :key="idx">
          <el-row>
            <el-col>
            <el-avatar :src="item.headImgUrl" style="float: left; margin: 15px 15px 0px 0px" ></el-avatar>
              <span style="font-size: 14px; margin-top: 20px; float: left; font-weight: bold">{{ userName }}</span>
            <span class="content-time" style="margin-top: 1vw">{{item.createTime}}</span>
            </el-col>
            <el-col style="margin-top: 10px; margin-left: 60px" >
              <el-col>
              <span class="content-content">{{item.content}}</span>
              </el-col>
            <el-col  v-if="item.fileArray.length > 0" style="width: 400px">
              <img :src="item1" style="height: 80px;width: 117px;margin: 8px 2px 0 0 " v-for="(item1,index) in  item.fileArray" :key="index">
            </el-col>
            <el-col :span="12" style="height: 1px">

            </el-col>
            <el-col :span="8" style="width: 30%;">

            </el-col>
            </el-col>
          </el-row>
          <el-row style="border-bottom: 1px dashed #D6D6D6">
            <el-col :span = "4" style="height: 3vw">
            </el-col>
            <el-col :span="12" style="height: 1px">

            </el-col>
            <el-col :span="8" style="height: 1px">
            </el-col>
          </el-row>
          </div>
        </el-row>
        <el-row>
          <el-input
              style="margin-top: 1vw;margin-left: 1vw;width: 94%"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="请输入内容"
              :disabled="editNumberValidateForm.state == 4"
              v-model="editNumberValidateForm.content">
          </el-input>
        </el-row>

        <el-form-item
            label="上传图片"
            prop="file"
            style="margin-top: 1vw"
        >
          <el-upload
              class="upload-demo"
              action=""
              :on-preview="handlePreview"
              :on-remove="handleEditRemove"
              :before-upload="beforUpload"
              multiple
              :limit="2"
              :http-request="editUploadFileOrImg"
              :on-exceed="handleExceed1"
              :file-list="editNumberValidateForm.urlList">
            <el-button size="small">点击上传</el-button>
          </el-upload>
        </el-form-item>

        <el-row type="flex" class="row-bg" justify="center" style="margin-top:1vw">
          <el-col :span="16">
            <el-button @click="resetForm('editNumberValidateForm')" :disabled="editNumberValidateForm.state == 4">取消</el-button>
            <el-button type="primary" @click="replyWork('editNumberValidateForm')" :disabled="editNumberValidateForm.state == 4">提交</el-button>
            <el-button type="primary" @click="closeWorkOrder('editNumberValidateForm')" :disabled="editNumberValidateForm.state == 4">已解决</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-drawer>

  </el-col>
</template>

<script>
import iView from 'iview'    //引入ivew库
import 'iview/dist/styles/iview.css'    // 使用 CSS
import Vue from 'vue'
import {optionsData} from '../../../utils/china-area'
import addWorkApi from "../../../api/buyer/addWork/addWorkApi";

Vue.use(iView)
export default {
  name: "address",
  data() {
    return {
      userName: this.$store.state.userInfo.userInfo.name,
      fileList: [],
      loading: true,
      textarea1:'',
      active: 1,
      radio:3,
      drawer: false,
      editDrawer:false,
      direction: 'rtl',
      numberValidateForm: {
        type:'',
        title:"",
        content:"",
        email:"",
        contactPhone:"",
        urlList:[]
      },
      editNumberValidateForm: {
        userType: 3,
        urlList: []
      },
      editData:[],
      rules: {
        email: [
          {required: true, message: '邮箱不能为空'},
          {type: 'email', message: '邮件格式不正确', trigger: 'change'}
        ],
        phone: [
          { required: true, message: '手机号码不能为空', trigger: 'change' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/
              if (!passwordreg.test(value)) {
                callback(
                    new Error(
                        '手机号码格式不正确'
                    )
                )
              } else {
                callback()
              }
            }
          }
        ],
        postalCode: [
          { required: true, message: '邮政编码不能为空'},
          { type: 'integer', message: '邮政编码格式不正确'},
          {
            validator : (rule, value, callback) => {
              if(!/^[1-9]\d{5}$/.test(value)) {
                return callback(new Error("邮政编码格式不正确"));
              }
              callback();
            }
          }
        ]
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 3
      },
      options: optionsData(),
    }
  },
  mounted() {
    this.getWorkOrderList();
  },
  methods: {
    onPageSizeChange(index) {
      this.page.pageSize = index;
      this.getWorkOrderList();
    },
    changePage(index) {
      this.page.pageNo = index;
      this.getWorkOrderList();
    },
    // 获取优惠券列表
    getWorkOrderList() {
      addWorkApi.getWorkOrderList({pageNo: this.page.pageNo, pageSize: this.page.pageSize}).then((res) => {
        this.tableData = [];
        this.page.total = 0;
        this.timer = setTimeout(()=>{   //设置延迟执行
          this.loading = false
        },300)
        if( res.code == 200 && res.data ) {

          this.tableData = res.data.records
          this.page.total = res.data.total

        }
      })
    },
    addWork(){

      let assign1 = Object.assign({},this.numberValidateForm);
      //assign1.type = 1;
      addWorkApi.addWork(assign1).then((res)=>{
        console.log(6666)
        if (res.code == 200){
          this.numberValidateForm = {
                type:'',
                title:"",
                content:"",
                email:"",
                contactPhone:"",
                urlList:[]
          };
          this.resetForm('numberValidateForm');
          this.$message({
            message: '新建工单成功',
            type: 'success',
            offset: 80
          });
          this.drawer =false
          this.getWorkOrderList();
        }else {
          this.$message.error('新建工单失败')
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("3333")
          this.addWork();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.editDrawer = false
      this.$refs[formName].resetFields();
    },
    paneActive(id) {
      this.active = id;
    },
    typeFormatter(row){
      //type == 1 ? '基础软件' : type == 2 ? '云产品' : type == 3 ? '解决方案' : ''
      switch (row.type){
        case 1:
          return '基础软件';
        case 2:
          return '云产品';
        case 3:
          return '解决方案';
        default:
          return '';
      }
    },
    stateFormatter(row){
      //1:等待受理，2:处理中，3:等待反馈，4:完结，5:已取消
      switch (row.state){
        case 1:
          return '等待受理';
        case 2:
          return '处理中';
        case 3:
          return '等待反馈';
        case 4:
          return '完结';
        case 5:
          return '已取消';
        default:
          return '';
      }
    },

    handleRemove(file, fileList) {
      fileList = fileList.filter((url) =>{
        return  url.uid !== file.uid
      })
      this.numberValidateForm.urlList = fileList
    },
    handleEditRemove(file, fileList) {
      fileList = fileList.filter((url) =>{
        return  url.uid !== file.uid
      })
      this.editNumberValidateForm.urlList = fileList
    },
    handlePreview(file) {
    },
    handleExceed(files, fileList) {
      this.$message({message: `当前限制选择 4 个文件`,type:'warning',customClass: 'messageIndex'})
    },
    handleExceed1(files, fileList) {
      this.$message({message: `当前限制选择 2 个文件`,type:'warning',customClass: 'messageIndex'})
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除1 ${ file.name }？`);
    },
    openEditDrawer(row){
      console.log("打开编辑框")
      this.editData = [];
      this.editNumberValidateForm = {};
      let param = {workOrderId: row.workOrderId};
      addWorkApi.selectWorkOrderReply(param).then((res) => {
        if(res.data) {
          this.editData = res.data;
          this.editData.forEach((item,index) =>{
            if (item.fileUrl){
            this.editData[index].fileArray = item.fileUrl.split(',')
            }else {
              this.editData[index].fileArray = []
            }
          })

          console.log(this.editData,'editData')
          // this.fileList =
        }
        this.editNumberValidateForm.title = row.title;
        this.editNumberValidateForm.workOrderId = row.workOrderId;
        this.editNumberValidateForm.state = row.state;
        this.editNumberValidateForm.urlList = [];
        if(!this.editDrawer){
          this.editDrawer = true;
          console.log(123)
          console.log(this.editData)
          console.log(this.editNumberValidateForm)
        }
      })
    },
    replyWork(){
      //{"workOrderId":"83bde769-24f1-4aa2-8e9e-4624ffc46754","title":"111","content":"7777","urlList":[],"userType":3}
      this.$refs['editNumberValidateForm'].validate((valid) => {
        if (valid) {
          let assign1 = Object.assign({},this.editNumberValidateForm);
          assign1.userType = 3;
          console.log(assign1,'assign1')
          if (assign1.content == undefined && assign1.urlList.length == 0){
            this.$message.error('消息内容不能为空')
            return ;
          }
          // assign1.urlList=this.editNumberValidateForm.urlList
          addWorkApi.replyWork(assign1).then((res)=>{
            if (res.code == 200){
              this.openEditDrawer({workOrderId: assign1.workOrderId});
              this.editNumberValidateForm.urlList=[];
            }
          })
        } else {
          return false;
        }
    });
    },
    closeWorkOrder(){
      let param = {workOrderId: this.editNumberValidateForm.workOrderId};
      addWorkApi.closeWorkOrder(param).then((res)=>{
        if (res.code == 200){
          this.editDrawer=false;
          this.$message.success('已解决成功')
        }else{
          this.$message.error('处理失败')
        }
      })
    },
    uploadFileOrImg(param){
      const formData = new FormData();
      formData.append('file',param.file)
      console.log(param)
      console.log(formData)
      console.log(param.file.name)
      //return;
      let imgSuffix= ['.png', '.jpg', '.bmp', '.gif'];
      //.png,.jpg,.bmp,.gif,.rar,.zip
      let limitImg = ['.png','.jpg','.bmp','.gif','.rar','.zip']

      let name = param.file.name;
      let i = name.lastIndexOf(".");
      let suffix = "";
      if (i > -1) {
        suffix = name.substring(i);
      }
      const number1 = limitImg.indexOf(suffix);
      if(number1<=-1){
        this.$message.error('只能上传.png,.jpg,.bmp,.gif,.rar,.zip文件')
        return;
      }
      const number = imgSuffix.indexOf(suffix);
      const size = param.file.size;
      if(number>-1){
          //图片上传
        const imgNumber = size/1024;
        console.log(imgNumber)
        if(imgNumber>2048){
          this.$message.success('您要上传的文件类型为图片,大小不能超过' + (2048 / 1024) + 'M')
          return;
        }
        addWorkApi.uploadImg(formData).then(data=>{
          console.log('图片上传成功')
          this.numberValidateForm.urlList.push({urlType: 1,name:param.file.name,url:data.data})
        }).catch(response=>{
          console.log('图片上传失败')
          console.log(response)
        })
      }else{
          //文件上传
        const fileNumber = size/1024;
        console.log(fileNumber)
        if(fileNumber>10240){
          this.$message.success('您要上传的文件类型为附件,大小不能超过' + (10240 / 1024) + 'M')
          return;
        }
        addWorkApi.uploadFile(formData).then(data=>{
          console.log('文件上传成功')
          this.numberValidateForm.urlList.push({urlType: 2,name:data.file.name,url:data.data})
        }).catch(response=>{
          console.log('文件上传失败')
          console.log(response)
        })
      }
    },
    editUploadFileOrImg(param){
      const formData = new FormData();
      formData.append('file',param.file)
      console.log(param)
      console.log(formData)
      console.log(param.file.name)
      //return;
      let imgSuffix= ['.png', '.jpg', '.bmp', '.gif'];
      //.png,.jpg,.bmp,.gif,.rar,.zip
      let limitImg = ['.png','.jpg','.bmp','.gif','.rar','.zip']

      let name = param.file.name;
      let i = name.lastIndexOf(".");
      let suffix = "";
      if (i > -1) {
        suffix = name.substring(i);
      }
      const number1 = limitImg.indexOf(suffix);
      if(number1<=-1){
        this.$message.error('只能上传.png,.jpg,.bmp,.gif,.rar,.zip文件')
        return;
      }
      const number = imgSuffix.indexOf(suffix);
      const size = param.file.size;
      if(number>-1){
        //图片上传
        const imgNumber = size/1024;
        console.log(imgNumber)
        if(imgNumber>2048){
          this.$message.success('您要上传的文件类型为图片,大小不能超过' + (2048 / 1024) + 'M')
          return;
        }
        addWorkApi.uploadImg(formData).then(data=>{
          console.log('图片上传成功')
          this.editNumberValidateForm.urlList.push({urlType: 1,name:param.file.name,url:data.data})
        }).catch(response=>{
          console.log('图片上传失败')
          console.log(response)
        })
      }else{
        //文件上传
        const fileNumber = size/1024;
        console.log(fileNumber)
        if(fileNumber>10240){
          this.$message.success('您要上传的文件类型为附件,大小不能超过' + (10240 / 1024) + 'M')
          return;
        }
        addWorkApi.uploadFile(formData).then(data=>{
          console.log('文件上传成功')
          this.editNumberValidateForm.urlList.push({urlType: 2,name:data.file.name,url:data.data})
        }).catch(response=>{
          console.log('文件上传失败')
          console.log(response)
        })
      }
    },
    beforUpload(file){
      //return false;
      let limitImg = ['.png','.jpg','.bmp','.gif','.rar','.zip']
      let name = file.name;
      let i = name.lastIndexOf(".");
      var suffix = "";
      if (i > -1) {
        suffix = name.substring(i);
      }
      const number1 = limitImg.indexOf(suffix);
      if(number1<=-1){
        //this.$message.error('只能上传.png,.jpg,.bmp,.gif,.rar,.zip文件')
        this.$message({
          message: '只能上传.png,.jpg,.bmp,.gif,.rar,.zip文件',
          type: 'error',
          offset: 80
        });
        return false;
      }
    },
    newAWork() {
      this.drawer = true
      this.$nextTick(()=>{
        this.resetForm('numberValidateForm')
      })
      this.numberValidateForm.type =1
    }

  },
  created() {

  }
}

</script>
<style>
.messageIndex {
  z-index: 3000 !important;
}
</style>
<style lang="less" scoped>

.reply-content {
  height: 44vh;
  overflow-y: auto;
  padding: 0 20px;
  overflow-x: hidden;
  box-shadow: 0 2px 0px -1px #cecece;
}
.content-time{
  display: inline-block;
  float: right;
  font-size: 14px;
  color: rgb(164, 164, 164);
}

.content-content{
  display: inline-block;
  width: 360px;
  //margin-top: 15px;
  font-size: 14px;
  word-break: break-all;
  color: rgb(164, 164, 164);
}

/deep/ .el-drawer__body{
  border-top:1px solid #D6D6D6
}
/deep/ .el-radio__input.is-checked .el-radio__inner{
  border-color:#F56E65;
  background:#F56E65;
}
/deep/ .el-radio__input.is-checked+.el-radio__label{
  color:#515a6e;
}

.page {
  margin: 20px 35px;
  width: 97%;

.title {
color: #F56E65;
font-size: 24px;
letter-spacing: 1px;
margin-bottom: 40px;
}

.pane-container {
height: 34px;

.pane {
width: 48px;
height: 16px;
font-size: 16px;
margin-right: 40px;
color: rgba(65, 65, 65, 1);

/deep/ .el-button {
  color: #fff;
  background-color: #ed4014;
  border-color: #ed4014;
  height: 32px;
  width: 88px;
  padding: 8px 16px;
}

/deep/ .el-button:hover {
  color: #fff;
  background-color: #ee5630;
  border-color: #ee5630;
}
}

.pane:active {
color: #F56E65;
}

.pane:hover {
color: #F56E65;
}

.pane-active {
color: #F56E65;
}

}

.table-container {
margin-top: 20px;

/deep/ .el-table .cell, .el-table--border .el-table__cell:first-child .cell {
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}

/deep/ .el-table thead {
color: #515A6E;
}
}

/deep/ .ivu-page {
float: right;
margin-right: 143px;
}

/deep/ .el-drawer__header {
height: 52px;
line-height: 20px;
font-size: 16px;
color: #17233d;
font-weight: 500;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;

}

/deep/ .el-drawer__container {
margin-top: 58px;
}

.drawer {
/deep/ .el-form-item__label {
text-align: left;
margin-left: 20px;
width: 79px !important;
}

/deep/ .el-form-item__content {
margin-left: 100px !important;
width: 421px;
}

/deep/ .el-cascader {
width: 421px;
}
/deep/ .el-button--primary {
width: 90px;
height: 32px;
color: #fff;
background-color: #ed4014;
border-color: #ed4014;
padding-top: 9px;
}
/deep/ .el-button+.el-button {
margin-left: 35px;
width: 90px;
height: 32px;
padding-top: 9px;
}


}
}
</style>
